import { Container, EContainerVariant } from '@front/shared/ds';
import React from 'react';

import { CardWide } from './CardWide';
import { usePreparedData } from './usePreparedData';

import type { Section } from '@shared/master-types';

type TCardWideConnectedProps = Extract<
  Required<Section>['rows'][number],
  { blockType: 'card-wide' }
>;

export const CardWideConnected: React.FC<TCardWideConnectedProps> = props => {
  const preparedCards = usePreparedData(props);

  const card = preparedCards[0];

  if (!card) {
    return null;
  }

  return (
    <Container
      variant={EContainerVariant.Full}
      className='max-w-[462px] tablet:max-w-full'
    >
      <CardWide {...card} />
    </Container>
  );
};

export default CardWideConnected;

import React from 'react';

import { ECardWideBlockType, ECardWideTheme, THEMES } from './constants';
import { Tabs } from './Tabs';

import type { TCardWideTab } from './Tabs';

export type TCardWideBlock = {
  type: ECardWideBlockType.Tabs;
  data?: TCardWideTab[];
};

type TBlocksProps = {
  blocks: TCardWideBlock[];
  theme?: ECardWideTheme;
};

export const Blocks: React.FC<TBlocksProps> = ({
  blocks,
  theme = ECardWideTheme.Light,
}) => {
  if (!blocks?.length) {
    return null;
  }

  const themeStyles = THEMES[theme] || THEMES[ECardWideTheme.Light];

  return blocks.map(({ type, data }, index) => {
    if (!data) {
      return null;
    }

    if (type === ECardWideBlockType.Tabs) {
      return (
        <Tabs
          key={index}
          tabs={data}
          tabClassName={themeStyles.tabBackgroundColor}
        />
      );
    }

    return null;
  });
};

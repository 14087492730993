import { toEnum, toImageProps, toRichText } from '@front/shared/ds';
import React, { useMemo } from 'react';

import {
  CardWide,
  ECardWideBackgroundImagePosition,
  ECardWideBackgroundImageSize,
  ECardWideBlockType,
  ECardWideTheme,
  TCardWideBlock,
} from './CardWide';

import type { Section } from '@shared/master-types';

type TUsePreparedDataProps = Extract<
  Required<Section>['rows'][number],
  { blockType: 'card-wide' }
>;

type TBlock = Required<
  Required<TUsePreparedDataProps>['cards'][number]
>['blocks'][number];

type TCard = React.ComponentProps<typeof CardWide>;

const prepareBlock = (block: TBlock): TCardWideBlock => {
  if (block.blockType === ECardWideBlockType.Tabs) {
    return {
      type: ECardWideBlockType.Tabs,
      data: (block.tabs || []).map(tab => ({
        text: tab.text,
        icon: toImageProps(tab.icon) || undefined,
      })),
    };
  }

  return {
    type: block.blockType,
  };
};

export const usePreparedData = (props: TUsePreparedDataProps) => {
  const { cards = [] } = props;

  return useMemo(
    () =>
      cards.map<TCard>(card => ({
        superTitle: toRichText(card.superTitle),
        title: toRichText(card.title),
        subTitle: toRichText(card.subTitle),
        blocks: (card.blocks || []).map(block => prepareBlock(block)),
        theme: toEnum(ECardWideTheme, card.theme),
        bgDesktopImage: toImageProps(card.bgDesktop?.image) || undefined,
        bgTabletImage: toImageProps(card.bgTablet?.image) || undefined,
        bgMobileImage: toImageProps(card.bgMobile?.image) || undefined,
        backgroundColor: card.backgroundColor,
        backgroundImageSize: toEnum(
          ECardWideBackgroundImageSize,
          card.backgroundImageSize,
        ),
        backgroundImagePosition: toEnum(
          ECardWideBackgroundImagePosition,
          card.backgroundImagePosition,
        ),
      })),
    [cards],
  );
};

import clsx from 'clsx';
import { EIconBottomPadding, ESimpleIconSize, Icon } from '@front/shared/ds';
import React from 'react';

import type { ImageProps } from 'next/image';

export type TCardWideTab = {
  text?: string;
  icon?: ImageProps;
};

type TTabsProps = {
  tabs?: TCardWideTab[];
  tabClassName?: string;
};

export const Tabs: React.FC<TTabsProps> = ({ tabs = [], tabClassName }) => {
  if (!tabs?.length) {
    return null;
  }

  return (
    <div className='mx-auto flex max-w-[820px] flex-row flex-wrap justify-center gap-2.5 py-10'>
      {tabs.map((tab, i) => (
        <div
          key={(tab.text || '') + i}
          className={clsx(
            'flex items-center gap-3 rounded-lg px-4 py-2 tablet:px-6 tablet:py-4',
            tabClassName,
          )}
        >
          {tab.icon ? (
            <Icon
              icon={tab.icon}
              bottomPadding={EIconBottomPadding.No}
              size={ESimpleIconSize.L}
            />
          ) : null}

          {tab.text ? (
            <div className='break-words text-sm font-medium leading-[21.7px] tracking-[0.32px] tablet:text-base tablet:leading-[24.8px]'>
              {tab.text}
            </div>
          ) : null}
        </div>
      ))}
    </div>
  );
};

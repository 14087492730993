export enum ECardWideTheme {
  Light = 'Light',
  Dark = 'Dark',
}

export enum ECardWideBackgroundImageSize {
  Cover = 'Cover',
  Contain = 'Contain',
}

export enum ECardWideBackgroundImagePosition {
  Top = 'Top',
  Center = 'Center',
  Bottom = 'Bottom',
}

type TCardWideTheme = {
  cardBackground: string;
  textColor: string;
  tabBackgroundColor: string;
};

export const THEMES: Record<ECardWideTheme, TCardWideTheme> = {
  [ECardWideTheme.Light]: {
    cardBackground: 'bg-[rgb(249,249,249)]',
    textColor: 'text-control-950',
    tabBackgroundColor: 'bg-[rgba(0,0,0,0.04)]',
  },
  [ECardWideTheme.Dark]: {
    cardBackground: 'bg-control-950',
    textColor: 'text-interface-50',
    tabBackgroundColor: 'bg-[rgba(255,242,242,0.07)]',
  },
};

export enum ECardWideBlockType {
  Tabs = 'tabs',
}

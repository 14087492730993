import Image, { ImageProps } from 'next/image';
import clsx from 'clsx';
import React from 'react';

import {
  ECardWideBackgroundImageSize,
  ECardWideBackgroundImagePosition,
} from './constants';

type TBackgroundProps = {
  bgImage?: ImageProps;
  backgroundImageSize?: ECardWideBackgroundImageSize;
  backgroundImagePosition?: ECardWideBackgroundImagePosition;
};

export const BackgroundImage: React.FC<TBackgroundProps> = ({
  bgImage,
  backgroundImageSize,
  backgroundImagePosition,
}) => {
  if (!bgImage) {
    return null;
  }

  return (
    <Image
      {...bgImage}
      className={clsx('absolute left-0 top-0 size-full', {
        'object-cover':
          backgroundImageSize === ECardWideBackgroundImageSize.Cover,
        'object-contain':
          backgroundImageSize === ECardWideBackgroundImageSize.Contain,
        'object-top':
          backgroundImagePosition === ECardWideBackgroundImagePosition.Top,
        'object-bottom':
          backgroundImagePosition === ECardWideBackgroundImagePosition.Bottom,
        'object-center':
          backgroundImagePosition === ECardWideBackgroundImagePosition.Center,
      })}
    />
  );
};

import clsx from 'clsx';
import React from 'react';

import {
  ECardWideBackgroundImageSize,
  ECardWideBackgroundImagePosition,
  ECardWideTheme,
  THEMES,
} from './constants';
import { Blocks, TCardWideBlock } from './Blocks';
import { BackgroundImage } from './BackgroundImage';
import { useBackgroundImage } from './useBackgroundImage';

import type { ImageProps } from 'next/image';

export type TCardWideProps = {
  superTitle?: React.JSX.Element | string;
  title?: React.JSX.Element | string;
  subTitle?: React.JSX.Element | string;
  blocks?: TCardWideBlock[];

  bgDesktopImage?: ImageProps;
  bgTabletImage?: ImageProps;
  bgMobileImage?: ImageProps;
  backgroundColor?: string;

  theme?: ECardWideTheme;
  backgroundImageSize?: ECardWideBackgroundImageSize;
  backgroundImagePosition?: ECardWideBackgroundImagePosition;
};

export const CardWide: React.FC<TCardWideProps> = ({
  superTitle,
  title,
  subTitle,
  blocks = [],

  bgDesktopImage,
  bgTabletImage,
  bgMobileImage,
  backgroundColor,

  theme = ECardWideTheme.Light,
  backgroundImageSize,
  backgroundImagePosition,
}) => {
  const themeStyles = THEMES[theme] || THEMES[ECardWideTheme.Light];

  const bgImage = useBackgroundImage({
    bgDesktopImage,
    bgTabletImage,
    bgMobileImage,
  });

  return (
    <div
      className={clsx(
        'relative flex min-h-[560px] flex-col justify-center overflow-hidden rounded-3xl tablet:min-h-[460px]',
        themeStyles.textColor,
        themeStyles.cardBackground,
      )}
      style={{
        ...(backgroundColor ? { backgroundColor } : {}),
      }}
    >
      <BackgroundImage
        bgImage={bgImage}
        backgroundImageSize={backgroundImageSize}
        backgroundImagePosition={backgroundImagePosition}
      />

      <div className='relative flex flex-col px-6 py-[72px]'>
        {superTitle ? (
          <div className='mx-auto max-w-[600px] text-center text-[96px] font-semibold leading-[116.16px] [overflow-wrap:anywhere] tablet:text-[134px] tablet:leading-[162.13px]'>
            {superTitle}
          </div>
        ) : null}

        {title || subTitle ? (
          <div className='mx-auto flex max-w-[600px] flex-col gap-6'>
            {title ? (
              <div className='text-center text-5xl font-semibold leading-loose [overflow-wrap:anywhere] tablet:text-8xl tablet:leading-snug'>
                {title}
              </div>
            ) : null}

            {subTitle ? (
              <div className='text-center text-4xl font-semibold leading-relaxed'>
                {subTitle}
              </div>
            ) : null}
          </div>
        ) : null}

        <Blocks blocks={blocks} theme={theme} />
      </div>
    </div>
  );
};

import { baseTailwindConfig } from '@front/shared';
import { useMedia } from 'react-use';

import type { ImageProps } from 'next/image';

type TUseBackgroundImageProps = {
  bgDesktopImage?: ImageProps;
  bgTabletImage?: ImageProps;
  bgMobileImage?: ImageProps;
};

export const useBackgroundImage = ({
  bgDesktopImage,
  bgTabletImage,
  bgMobileImage,
}: TUseBackgroundImageProps) => {
  const isDesktopBreakpoint = useMedia(
    `(min-width: ${baseTailwindConfig.screens.desktop})`,
    true,
  );

  const isTabletMedia = useMedia(
    `(min-width: ${baseTailwindConfig.screens.tablet})`,
    true,
  );

  const isTabletBreakpoint = isTabletMedia && !isDesktopBreakpoint;
  const isMobileBreakpoint = !isTabletMedia;

  let bgImage;
  if (isDesktopBreakpoint && bgDesktopImage) {
    bgImage = bgDesktopImage;
  }
  if (isTabletBreakpoint && bgTabletImage) {
    bgImage = bgTabletImage;
  }
  if (isMobileBreakpoint && bgMobileImage) {
    bgImage = bgMobileImage;
  }

  return bgImage;
};
